import './HomePage.scss';

import PageHero from '../../components/PageHero/PageHero';
import PageBody from '../../components/PageBody/PageBody';

export default function HomePage() {
    return (
        <>
                <PageHero />
                <PageBody />
        </>
    )
}